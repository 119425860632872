const elementsMassForm = Vue.component('element-mass-form', {
  data: () => ({
    data: null,
    valids: {},
    check: true,
    actives: {},
    form: null
  }),
  computed: {
    formValid: function () {
      
      let valid       = true
      let fieldActive = false

      for(let field in this.valids) {
        if (this.valids.hasOwnProperty(field)) {
          valid &= this.valids[field]
        }
      }

      for(let field in this.actives) {
        if (this.actives.hasOwnProperty(field)) {
          if (this.actives[field]) {
            fieldActive = true
          }
        }
      }

      return valid && fieldActive
    }
  },
  methods: {
    goToList: function () {
      this.$router.push({path: '/' + this.$route.params.formname})
    },
    loadInfos : function () {
      let me = this

      this.server.get('/get/infos/' + this.$route.params.formname, false).then((data) => {
        me.form = data
        this.server.get('/get/commonfields/' + me.$route.params.formname + '/' + me.$route.params.idselements, false).then((data) => {
          me.data =  data
        })
      })
      
      
    },
    saveElement: function () {
      
      let data = {}
      this.form.sections.forEach(section => {
        section.champs.forEach(champ => {
          if (this.actives[champ.nom]) {
            data[champ.nom] = this.data[champ.nom]
          }
        })
      })
      
      this.server.post('/get/masssaveelement/' + this.$route.params.formname, {data, idselements: this.$route.params.idselements.split(',')}).then((data) => {
        this.$store.commit('setDataUpdated')
        this.$router.push({ path: '/' + this.$route.params.formname })
      })
    }
  },
  mounted: function () {
    this.loadInfos()
  },
  template: `
    <div class="element-form">
      <div class="menu-bar">
        <!-- <a v-show="!editing" href="#" class="button w-150 green" @click.prevent="createNewElement(formname)"><i aria-hidden="true" class="fa fa-plus"></i> Nouveau</a> -->
        <!-- <a v-show="!editing" href="#" class="button w-150 blue"><i aria-hidden="true" class="fa fa-list"></i> Liste</a> -->
        <!-- <router-link v-show="!editing" class="button w-150 blue" :to="'/' + formname"><i aria-hidden="true" class="fa fa-list"></i> Liste</router-link> -->
        <a :class="{'disabled': !formValid}" href="#" class="button w-150 blue" @click.prevent="saveElement"><i aria-hidden="true" class="fa fa-floppy-o"></i> Enregistrer</a>
        <a href="#" class="button w-150 red" @click.prevent="goToList"><i aria-hidden="true" class="fa fa-times"></i> Annuler</a>
      </div>
      <table class="table-form" v-if="form && data">
        <tr><td colspan="3">
          <h2>{{ form.titre }} ({{ $route.params.idselements ? $route.params.idselements.split(',').length : 0 }} éléments)</h2>
          
        </td></tr>
        <tbody v-for="section in form.sections">
          <tr v-if="form.sections.length > 1">
            <td colspan="2"><h4>{{ section.titre }}</h4></td>
          </tr>
          <tr 
            is="champ"
            v-for="champ in section.champs"
            v-show="!champ.invisible"
            :champ="champ"
            :editable="true"
            v-model="data[champ.nom]"
            :check="check"
            :valide.sync="valids[champ.nom]"
            :active.sync="actives[champ.nom]"
            :formname="$route.params.formname"
            :activable="true"
          ></tr>
        </tbody>
      </table>
    </div>
  `
})