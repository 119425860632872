Vue.component('in-file', {
  data: function() {
    return {
      id: "bu" + parseInt(1000000 * Math.random())
    }
  },
  watch: { },
  props: {},
  computed: { },
  methods: {  },
  mounted: function () {
    var me = this, uploader = new ss.SimpleUpload({
      button: this.id,
      url: '/upload', // server side handler
      responseType: 'string',
      name: 'file',
      dropzone: this.id,
      multiple: false,

      onComplete:   function(filename, response) {
        me.$emit("add", response)
      }
    });
  },
  template: `
    <div>
      <a href="#" :id="id" class="button w-150 green"><i class="fa fa-plus" aria-hidden="true"></i> Fichier</a>
    </div>
  `
})

