Vue.component('in-binaire', {
  props: ['value'],
  watch: {
    value: function (v) {
      this.$emit('input', this.value);
    }
  },
  template: `
    <div class="in-binaire">
      <i v-show="value == '1'" class="fa fa-check-square-o fa-lg" aria-hidden="true" @click="value = '0'"></i>
      <i v-show="value == '0' || !value" class="fa fa-square-o fa-lg" aria-hidden="true" @click="value = '1'"></i>
    </div>
  `
})