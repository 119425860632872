Vue.component("champ", {
  props: {
    value: {},
    champ: {},
    editable: {},
    check: {},
    valide: {},
    formname: {},
    activable: { default: false },
    active: { default: true },
  },
  data: function () { return {
    currentValue: this.value,
    messageErreur: ""
  }},
  methods: {
    swtichActive: function () {
      this.$emit('update:active', this.active ? false : true)
    },
    sousTitre: function () {
      if (this.editable) {
        switch (this.champ.type) {
          case 'li':
            return 'ENTRÉE pour valider un item'
            break;
          case 'da':
            return 'Format : AAAA-MM-JJ'
            break;
        }
      }
      return false
    },
    addFile: function (data) {
      // console.log('data :', data);
      this.currentValue = this.currentValue || ''
      this.currentValue = (this.currentValue.replace(/:/g, '').trim().length !== 0 ? this.currentValue + '|' : '') + data
      // console.log('this.currentValue :', this.currentValue);
      this.$forceUpdate()
    },
    checkValue: function () {
      this.currentValue = this.currentValue || ''

      if (this.activable && !this.active) {
        this.$emit('update:valide', true)
        return
      }
      
      var regexDate = RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$')
      this.$emit('update:valide', true)
      // this.valide = true

      if (this.champ.obligatoire && this.currentValue.trim() === "") {
        this.messageErreur = "Ce champ est obligatoire !"
        this.$emit('update:valide', false)
      }
      if (this.champ.type === "da" && !regexDate.test(this.currentValue) && this.champ.obligatoire) {
        this.messageErreur = "Date invalide"
        this.$emit('update:valide', false)
      } 
      if (this.champ.type === "da" && !regexDate.test(this.currentValue) && !this.champ.obligatoire && this.currentValue.trim() !== "") {
        this.messageErreur = "Date invalide"
        this.$emit('update:valide', false)
      }
      if (this.champ.motif) {
        var regex = new RegExp(this.champ.motif)
        if (!regex.test(this.currentValue)) {
          this.messageErreur = "Le format n'est pas correct"
          this.$emit('update:valide', false)
        }
      }      
    }
  },
  watch: {
    value: function (v) {
      
      this.currentValue = v || ''
    },
    currentValue: function (v) {
      this.checkValue()
      if (v.trim() === '' && (this.champ.type === "da" || this.champ.type === "nb" || this.champ.type === "bi")) {
        v = null
      }
      this.$emit('input', v)
    },
    check: function () {
      this.checkValue()
    },
    active: function () {
      this.checkValue()
    }
  },
  mounted: function () {
    this.$emit('update:active', this.activable ? false : true)

    this.checkValue()
  },
  template: `
  <tr>
    <td v-if="activable" class="pr-20">
      <a v-if="!champ.lectureseule" href="#" @click.prevent="swtichActive" class="a-check-active">
        <i :class="{ 'fa-check-square-o' : active, 'fa-square-o' : !active }" class="fa" aria-hidden="true"></i>
      </a>
    </td>
    <td class="label">
      <label class="form-control-label" :for="champ.nom">
        {{ champ.titre }}<span v-if="champ.obligatoire">*</span>&nbsp;:
        <span v-if="sousTitre(champ)" class="sous-label"><br>{{ sousTitre(champ) }}</span>
      </label>
    </td>
    <td v-if="editable && !champ.lectureseule && this.active">
      
      <input v-if="champ.type == 'tx' && !champ.ac" v-model="currentValue" type="text" class="form-control" :name="champ.nom" >
      <in-ac v-else-if="champ.type == 'tx' && champ.ac" v-model="currentValue" :field="champ.nom" :formname="formname"></in-ac>
      <input v-else-if="champ.type == 'nb'" v-model="currentValue" type="text" class="form-control" :name="champ.nom" style="width:80px">
      <input v-else-if="champ.type == 'da'" v-model="currentValue" type="text" class="form-control" :name="champ.nom" size="10" maxlength="10" style="width:100px">
      <div v-else-if="champ.type == 'cu'" class="form-check">
        <div v-for="valeur in champ.valeurs"><label>
            <input type="radio" :name="champ.nom" :value="valeur.valeur" v-model="currentValue">
            {{ valeur.titre }}
        </label></div>
      </div>
      <in-multi v-else-if="champ.type == 'li'" v-model="currentValue" :ac="champ.ac" :champ="champ.nom" :formname="formname"></in-multi>
      <in-binaire v-else-if="champ.type == 'bi'" v-model="currentValue"></in-binaire>
      <textarea v-else-if="champ.type == 'lt'" class="form-control" rows="3"  v-model="currentValue"></textarea>
      <in-file v-else-if="champ.type == 'fi'" @add="addFile($event, champ.nom)"></in-file>

      <select v-else-if="champ.type == 'ar'" v-model="currentValue">
        <template v-for="val in champ.valeurs">
          <option v-if="!val.groupe" :value="val.valeur">{{ (val.indent ? '&nbsp;'.repeat(4 * val.indent) : '') + val.titre }}</option>
          <optgroup v-else :label="(val.indent ? ' '.repeat(4 * val.indent) : '') + val.titre">
            <option v-for="subval in val.valeurs" :value="subval.valeur">{{ (subval.indent ? '&nbsp;'.repeat(4 * subval.indent) : '') + subval.titre }}</option>
          </optgroup>
        </template>
      </select>
      <div v-if="champ.type == 'fi'">
        <div v-for="file in currentValue ? currentValue.split('|') : []">
          <a :href="'/file/' + file.split(':')[0]" class="fs-14">{{file.split(':')[1]}}</a>
        </div>
      </div>
      <div v-if="!valide" class="invalid-message">{{ messageErreur }}</div>
      <!--<div v-if="champ.obligatoire" class="invalid-feedback" :data-champ="champ.nom">Ce champ est obligatoire</div>-->

    </td>
    <td v-else="">
      <span v-if="champ.type == 'bi'">
        <span class="in-span" v-show="currentValue == '1'">Oui</span>
        <span class="in-span" v-show="currentValue == '0'">Non</span>
      </span>
      <span v-else-if="champ.type == 'li'">
      <ul v-if="currentValue" class="in-li-static">
          <li v-for="val in currentValue.split('|')" v-if="val.trim() != ''">
            <i class="fa fa-caret-right" aria-hidden="true"></i> {{ val }}
          </li>
        </ul>
      </span>
      <span v-else-if="champ.type == 'ar'">
        <span v-for="val in champ.valeurs">
          <span class="in-span" v-for="subval in val.valeurs" v-show="subval.valeur == currentValue">{{ subval.titre }}</span>
        </span>
      </span>
      <span v-else-if="champ.type == 'cu'">
        <div v-for="valeur in champ.valeurs">
          <span class="in-span" v-show="currentValue == valeur.valeur">{{ valeur.titre }}</span>
        </div>
      </span>
      <div v-else-if="champ.type == 'fi'" v-for="file in currentValue ? currentValue.split('|') : []">
        <a :href="'/file/' + file.split(':')[0]" class="fs-14">{{file.split(':')[1]}}</a>
      </div>
      <span v-else="" class="in-span">{{ currentValue }}</span>
    </td> 
  </tr>
  `
});