Vue.component('modal-message', {
  methods: {
    hideModal: function () { this.$store.commit('hideModal') },
    execCallback: function () { 
      this.$store.commit('hideModal')
      this.$store.state.modal.okCallback()
    }
  },
  computed: {
    modal: function () { return this.$store.state.modal },
  },
  template: `
    <div class="modal-message" v-show="modal.visible">
      <!--<img src="images/loading.gif"><br>-->
      <div>
        <div class="title">
          {{ modal.title }}
        </div>
        <div class="message">
          {{ modal.message }}
        </div>
        <div class="buttons">
          <a v-show="modal.info" href="#" class="button w-100 blue" @click.prevent="hideModal"><i aria-hidden="true" class="fa fa-check"></i> OK</a>
          <a v-show="!modal.info" href="#" class="button w-100 green" @click.prevent="execCallback"><i aria-hidden="true" class="fa fa-check"></i> OK</a>
          <a v-show="!modal.info" href="#" class="button w-100 red" @click.prevent="hideModal"><i aria-hidden="true" class="fa fa-times"></i> Annuler</a>
        </div>
      </div>
    </div>
  `
})