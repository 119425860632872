Vue.component('lock', {
  computed: {
    connected: function () { return this.$store.state.userInfos.connected },
    encodedPath: function () { return this.$route.fullPath.replace(/\//g, '_') }
  },
  methods: {
    unlock: function () { this.$store.commit('login') },
    logout: function () { this.$store.commit('logout') }
  },
  template: `
    <div class="lock">
      <span v-if="connected">{{ $store.state.userInfos.fullName }}</span>
      <a :href="'/login/' + encodedPath" v-show="!connected"><i class="fa fa-lock" aria-hidden="true"></i></a>
      <a href="/logout" v-show="connected"><i class="fa fa-sign-out" aria-hidden="true"></i></a>
    </div>
  `
})