const elementsList = Vue.component('elements-list', {
  data: () => {
    return {
      exportValues: [
        { label: 'Exporter en PDF', data: 'pdf' },
        { label: 'Exporter en Excel', data: 'xls' },
        { label: 'Exporter en CSV', data: 'csv' }
      ],
      elements: [],
      form: {},
      total : null,
      queryParams: {
        start: 0,
        limit: 200,
        orderBy: null,
        orderDesc: false,
        search: null,
        where: []
      },
      searchNow: false,
      searchTimeout: null,
      elements: [],
      fieldsList: [],
      displayedFields: [],
      columnsChoiceVisible: false,
      formFieldsByName: {},
      groupsElements: [],
      groupsActivated: [],
      groupsInitialized: false,
      groupsValues: [],
      allSelected: false,
      selectedElements: [],
      currentPage: 1,
      keepPage: false
    }
  },
  watch: {
    queryParams: {
      handler: function () {
        let me = this
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout)
          this.searchTimeout = null
        }
        this.searchTimeout = setTimeout(
          () => {
            me.searchNow  = false
            if (!me.keepPage) {
              me.currentPage = 1
              me.queryParams.start = 0
            }
            me.keepPage   = false
            me.loadElements()
            me.loadGroupsElements()
            me.countElements()
          }, me.searchNow ? 0 : 200
        )
        
      },
      deep: true
    }
  },
  computed: {
    lastItem: function () {
      return Math.min(this.queryParams.start + this.queryParams.limit, this.total)
    },
    nPages: function () {
      return Math.ceil((this.total || 0) / this.queryParams.limit)
    },
    rwAccess: function () {
      if (!this.form.rwAccess) { return true }
      if (!this.$store.state.userInfos.connected) { return false }
      return this.form.rwAccess.indexOf(this.$store.state.userInfos.login) !== -1
    }
  },
  methods: {
    reloadInfos: function () {
      this.server.get('/get/infos/' + this.$route.params.form).then((data) => {
        this.form = data
        this.genFieldsList()
        if (this.form.defaultSort) {
          this.queryParams.orderBy = this.form.defaultSort.field || null
          this.queryParams.orderDesc = this.form.defaultSort.asc ? false : true
        } 
        this.initGroupsElements()
        this.loadGroupsElements()
        this.loadElements()
  
      })
      
      this.countElements()
    },
    openElement: function (element) {
      this.$router.push({ 
        name: 'elementForm',
        params: { formname: this.form.nom, elementid: element.id }
      })
    },
    launchMassEdit: function () {
      this.$router.push({ 
        name: 'elementsMassForm',
        params: { formname: this.form.nom, idselements: this.selectedElements.map((element) => element.id).join(',') }
      })
    },
    massDelete: function () {
      let me = this

      this.$store.commit('setModal', {
        visible: true,
        message: 'Êtes-vous sûr de vouloir supprimer ' + this.selectedElements.length + ' éléments ?',
        title: 'Confirmation de suppression',
        info: false,
        okCallback: () => {
          me.server.get('/get/massdelete/' + me.form.nom + '/' +  this.selectedElements.map((element) => element['uniqueId']).join(',')).then((data) => {
            this.$store.commit('setDataUpdated')
          })
        }
      })
    },
    genFieldsList: function () {
      let me = this
      this.form.sections.forEach(section => {
        section.champs.forEach(champ => {
          if (me.form.colonnesParDefaut && me.form.colonnesParDefaut.indexOf(champ.nom) !== -1) {
            me.displayedFields.push(champ)
          }

          if (champ.nom === me.form.champTitre) {
            me.displayedFields.push(champ)
          }
          me.fieldsList.push(champ)
          me.formFieldsByName[champ.nom] = champ
        })
      })
    },
    initGroupsElements: function () {
      if (this.form.groups) {
        for (let i = 0; i < this.form.groups.length; i += 1) {
          this.groupsElements[i] = []
          this.groupsActivated[i] = []
          this.groupsValues[i] = []
          for (let j = 0; j < this.form.groups[i].length; j += 1) {
            this.groupsElements[i][j] = []
            this.groupsActivated[i][j] = false
            this.groupsValues[i][j] = ''
          }
        }
      }
      this.groupsInitialized = true
    },
    loadGroupsElements: function () {
      let me = this

      if (this.form.groups) {
        for (let i = 0; i < this.form.groups.length; i += 1) {
          // this.groupsElements[i] = []
          for (let j = 0; j < this.form.groups[i].length; j += 1) {
            if (j === 0 || this.groupsActivated[i][j-1]) {
              let queryParams = Object.assign({}, this.queryParams)
              queryParams.where = []
              for (let k = 0; k < this.queryParams.where.length; k += 1){
                if (this.queryParams.where[k].indexOf('`' + this.form.groups[i][j] + '`') === -1) {
                  queryParams.where.push(this.queryParams.where[k])
                }
              }
              this.server.post('/get/distinctelements/' + this.$route.params.form + '/' + this.form.groups[i][j], queryParams).then((data) => {
                me.groupsElements[i][j] = data 
              })

              // this.groupsElements[i][j] = ['bip', 'bippe']
            }
          }
        }
      }
    },
    updateGroupsFilter: function (e, indexGroup, indexElement) {
      let value = e.currentTarget.value
      
      if (value.trim() === '') {
        this.groupsActivated[indexGroup][indexElement] = false
        for (let i = indexElement; i < this.form.groups[indexGroup].length; i += 1) {
          this.groupsActivated[indexGroup][i] = false
        }
      } else {
        this.groupsActivated[indexGroup][indexElement] = true
      }

      this.genFiltersWhere()
      this.loadGroupsElements()
    },
    genFiltersWhere: function () {
      this.queryParams.where = []
      if (this.form.groups) {
        for (let i = 0; i < this.form.groups.length; i += 1) {
          for (let j = 0; j < this.form.groups[i].length; j += 1) {
            if (this.groupsActivated[i][j]) {
              this.queryParams.where.push('`' + this.form.groups[i][j] + "` = '" + this.groupsValues[i][j].replace(/'/g, "\\'") + "'")
            }
          }
        }
      }

    },
    switchColumn: function (field) {
      let index = this.columnVisible(field)
      if (index !== null) {
        this.displayedFields.splice(index, 1)
      } else {
        this.displayedFields.push(field)
      }
      
    },
    columnVisible: function (field) {
      let index = this.displayedFields.indexOf(field)

      return index !== -1 ? index : null
    },
    formatFieldValue: function (element, field) {
      var i;
      if (!element[field.nom]) { return '' }
      if (field.type === 'li') {
        return element[field.nom].replace(/\|/g, '<br>');
      } else if (field.type === 'fi' && element[field.nom]) {
        return '<a href="/file/' + element[field.nom].split(':')[0] + '" class="fs12">' + element[field.nom].split(':')[1] + '</a>'
      } else if (field.type === 'da' && element[field.nom]) {
        return this.formatDate(element[field.nom])
      }else if (field.type === 'bi') {
        return element[field.nom] === '0' ? 'non' : 'oui';
      }  else if (field.type === 'cu') {
        for (i = 0; i < field.valeurs.length; i += 1) {
          if (field.valeurs[i].valeur === element[field.nom]) {
            return field.valeurs[i].titre;
          }
        }
      } else {
        return element[field.nom];
      }
    },
    moveColumn: function (field, left) {
      let index = this.columnVisible(field)
      this.displayedFields.splice(index, 1)
      this.displayedFields.splice(index + (left ? -1 : 1), 0, field)
    },
    loadElements: function () {
      this.server.post('/get/elements/' + this.$route.params.form, this.queryParams).then((data) => { 
        data.forEach(element => { element.selected = false });
        this.elements = data
      })
    },
    getFile: function (format) {

      let queryParams = JSON.parse(JSON.stringify(this.queryParams))
      queryParams.start = null
      queryParams.limit = null
      this.server.post('/get/' + format + '/' + this.$route.params.form, queryParams).then((data) => {
        const anchor = document.createElement('a')
        anchor.href = '/exportfile/' + data.fileName
        anchor.download = name
        anchor.click()
        console.log('data :', data.fileName);
      })
    },
    countElements: function () {
      this.server.post('/get/countElements/' + this.$route.params.form, this.queryParams).then((data) => { this.total = data })
    },
    setOrder: function (field) {
      if (this.queryParams.orderBy === field.nom) {
        this.queryParams.orderDesc = !this.queryParams.orderDesc
      } else {
        this.queryParams.orderBy = field.nom
        this.queryParams.orderDesc = false
      }
    },
    selectElement: function (element) {
      if (element.selected) {
        this.selectedElements.splice(this.selectedElements.indexOf(element), 1)
      } else {
        this.selectedElements.push(element)
      }
      element.selected = !element.selected
    },
    selectAll: function () {
      this.selectedElements.splice(0, this.selectedElements.length)

      if (this.allSelected) {
        this.allSelected = false
        this.elements.forEach(element => { element.selected = false })
      } else {
        this.allSelected = true
        this.elements.forEach(element => {
          element.selected = true
          this.selectedElements.push(element)
        })
      }
    }
  },
  mounted: function () {
    this.reloadInfos()
  },
  template: `
    <div class="elements-list">
      <div class="menu-top">
        <ul>
          <li class="blue">
            <router-link v-if="$route.path != '/formslist'" to="/formslist">
              <span class="icon"><span class="icon-home"></span></span>
              <span class="label">Accueil</span>
            </router-link>
          </li>
          <li class="green">
            <a v-if="rwAccess" href="#" @click.prevent="createNewElement($route.params.form)" >
              <span class="icon"><span class="icon-plus"></span></span>
              <span class="label">Nouveau</span>
            </a>
          </li>
          <li v-show="selectedElements.length > 1" class="violet">
            <a v-if="rwAccess" :class="{'disabled' : selectedElements.length < 2}" href="#" @click.prevent="launchMassEdit">
              <span class="icon"><span class="icon-edit"></span></span>
              <span class="label">Éditer en masse</span>
            </a>
          </li>
          <li v-show="selectedElements.length > 1" class="red">
            <a v-if="rwAccess" :class="{'disabled' : selectedElements.length < 1}" href="#" @click.prevent="massDelete">
              <span class="icon"><span class="icon-delete-button"></span></span>
              <span class="label">Supprimer en masse</span>
            </a>
          </li>
          <li class="small" :class="{ 'red': $store.state.userInfos.connected, 'blue': !$store.state.userInfos.connected }">
            <a :href="'/login/' + encodedPath" v-if="!$store.state.userInfos.connected">
              <span class="icon"><span class="icon-lock"></span></span>
              <span class="label">Connexion</span>
            </a>
            <a href="/logout" v-if="$store.state.userInfos.connected">
              <span class="icon"><span class="icon-sign-out"></span></span>
              <span class="label">{{ 'Déconnexion : ' + $store.state.userInfos.fullName }}</span>
            </a>
          </li>
          <li class="green">
            <a href="#" @click.prevent="getFile('xls')">
              <span class="icon"><i class="fa fa-file-excel-o" aria-hidden="true"></i></span>
              <span class="label">Exporter en Excel</span>
            </a>
          </li>
          <li class="red">
            <a href="#" @click.prevent="getFile('pdf')">
              <span class="icon"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></span>
              <span class="label">Exporter en PDF</span>
            </a>
          </li>
          <li class="green">
            <a href="#" @click.prevent="getFile('csv')">
              <span class="icon"><i class="fa fa-table" aria-hidden="true"></i></span>
              <span class="label">Exporter en CSV</span>
            </a>
          </li>
          <!-- <li>
            <multi-button :values="exportValues" v-on:choose="getFile">
              <i aria-hidden="true" class="fa fa-floppy-o"></i>Exporter	&#11206;
            </multi-button>
          </li> -->
        </ul>
      </div>

      <!-- <div class="menu-bar">
        <a v-if="rwAccess" href="#" class="button w-150 green" @click.prevent="createNewElement($route.params.form)"><i aria-hidden="true" class="fa fa-plus"></i> Nouveau</a>
        <a v-if="rwAccess" :class="{'disabled' : selectedElements.length < 2}" href="#" class="button violet w-200" @click.prevent="launchMassEdit"><i aria-hidden="true" class="fa fa-pencil"></i>Éditer en masse ({{ selectedElements.length }})</a>
        <a v-if="rwAccess" :class="{'disabled' : selectedElements.length < 1}" href="#" class="button red w-200" @click.prevent="massDelete"><i aria-hidden="true" class="fa fa-pencil"></i>Supprimer en masse ({{ selectedElements.length }})</a>
        <multi-button :values="exportValues" v-on:choose="getFile">
          <i aria-hidden="true" class="fa fa-floppy-o"></i>Exporter	&#11206;
        </multi-button>
      </div> -->
      <h2>{{ form.titre }}</h2>
      <div class="txt-center fs-13 txt-darkgrey">{{ queryParams.start + 1 }} à {{ lastItem }} sur {{ total }}</div>
      <div class="pages" v-show="nPages > 1">
        <a href="#" v-for="i in nPages" @click.prevent="keepPage = true; currentPage = i; queryParams.start = (i-1) * queryParams.limit" :class="{'active' : currentPage == i}">{{ i }}</a>
      </div>
      <div class="filters">
        <div class="search-field">
          <i aria-hidden="true" class="fa fa-search fs-18 txt-darkgrey"></i>
          <input v-model="queryParams.search" type="text" placeholder="Recherche...">
        </div>
        <div class="fields-groups" v-if="form.groups" v-if="groupsInitialized">
          <span v-for="(group, indexGroup) in form.groups">
            <span v-for="(element, indexElement) in group"  v-show="indexElement == 0 || groupsActivated[indexGroup][indexElement - 1]">
              <span  class="title">{{ formFieldsByName[element] ? formFieldsByName[element].titre : "" }} :</span>
              <select
                @change="updateGroupsFilter($event, indexGroup, indexElement)"
                v-model="groupsValues[indexGroup][indexElement]"
              >
                <option value="">-- Aucun Filtre --</option>
                <option v-for="element in groupsElements[indexGroup][indexElement]" :value="element">{{ element }}</option>
              </select>
            </span>
          </span>
        </div>
      </div>
      
      <div class="columns-choice">
        <a href="#" class="button small w-150"
          @click.prevent="columnsChoiceVisible = !columnsChoiceVisible"
          v-html="columnsChoiceVisible ? '<i class=\&quot;fa fa-times\&quot; aria-hidden=\&quot;true\&quot;></i> Fermer' : 'Choix des colonnes'"
          :class="{'red': columnsChoiceVisible, 'grey': !columnsChoiceVisible}"
        ></a>
        <ul v-show="columnsChoiceVisible">
          <li v-for="field in fieldsList"><a href="#" @click.prevent="switchColumn(field)">
            <i class="fa" :class="{'fa-check-square-o' : columnVisible(field) != null, 'fa-square-o' : columnVisible(field) == null}" aria-hidden="true"></i>
            {{ field.titre }}
          </a></li>
        </ul>
      </div>
      
      <table class="elements">
        <thead>
          <tr>
            <th>
              <a v-if="rwAccess" href="#" @click.prevent="selectAll">
                <i :class="{ 'fa-check-square-o' : allSelected, 'fa-square-o' : !allSelected }" class="fa" aria-hidden="true"></i>
              </a>
            </th>
            <th v-for="(field, index) in displayedFields">
              <a href="#" class="move-left" v-show="index != 0" @click.prevent="moveColumn(field, true)"><i class="fa fa-caret-left" aria-hidden="true"></i></a>
              <a href="#" @click.prevent="searchNow = true; setOrder(field)">{{ field.titre }}</a>
              <a href="#" class="move-right" v-show="index != displayedFields.length - 1" @click.prevent="moveColumn(field, false)"><i class="fa fa-caret-right" aria-hidden="true"></i></a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in elements">
            <td>
              <a v-if="rwAccess" href="#" @click.prevent="selectElement(element)">
                <i :class="{ 'fa-check-square-o' : element.selected, 'fa-square-o' : !element.selected }" class="fa" aria-hidden="true"></i>
              </a>
            </td>
            <td v-for="(field, index) in displayedFields">
              <a v-if="index == 0" href="#" @click.prevent="openElement(element)" v-html="formatFieldValue(element, field) || '<em>(vide)</em>'"></a>
              <span v-else="" v-html="formatFieldValue(element, field)" ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `
})