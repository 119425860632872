const elementForm = Vue.component('element-form', {
  // props: ['formname', 'elementid', 'editing'],
  data: () => ({
    data: null,
    versions: {},
    valids: {},
    check: true,
    form: null,
    editing: false
  }),
  computed: {
    formValid: function () {
      let valid = true
      for(let field in this.valids) {
        if (this.valids.hasOwnProperty(field)) {
          valid &= this.valids[field]
        }
      }
      return valid
    },
    rwAccess: function () {
      if (!this.form) { return true }
      if (!this.form.rwAccess) { return true }
      if (!this.$store.state.userInfos.connected) { return false }
      return this.form.rwAccess.indexOf(this.$store.state.userInfos.login) !== -1
    }
  },
  methods: {
    loadInfos : function () {
      let me = this

      this.server.get('/get/infos/' + this.$route.params.formname, false).then((data) => {
        me.form = data

        me.server.get('/get/element/' + me.$route.params.formname + '/' + me.$route.params.elementid, false).then((data) => {
          if (data.data.dateCreation === null) {
            me.editing = true
          }
          me.data     = data.data
          me.versions = data.versions
        })
      })
      
      
    },
    saveElement: function () {
      console.log('this.data :', this.data);
      this.server.post('/get/saveelement/' + this.$route.params.formname, this.data).then((data) => {
        this.$store.commit('setDataUpdated')
        this.editing = false;
        this.$router.push({path: '/' + this.$route.params.formname + '/' + data})
      })
    },
    restoreCurrent: function () {
      let me = this

      this.$store.commit('setModal', {
        visible: true,
        message: 'Êtes-vous sûr de vouloir restaurer la version n°' + me.versions.currentVersion + ' de cet élément ?',
        title: 'Confirmation de restauration',
        info: false,
        okCallback: () => {
          me.server.get('/get/restoreelement/' + me.$route.params.formname + '/' +  me.$route.params.elementid).then((data) => {
            this.$store.commit('setDataUpdated')
            console.log('data :', data);
          })
        }
      })
    }
  },
  // beforeRouteUpdate (to, from, next) {
  //   this.loadInfos()
  //   next()
  // },
  mounted: function () {
    console.log('mount')
    this.loadInfos()
  },
  template: `
    <div class="element-form">
    <div class="elements-list">
      <div class="menu-top">
        <ul>
          <li class="blue">
            <router-link v-if="$route.path != '/formslist'" to="/formslist">
            <span class="icon"><span class="icon-home"></span></span>
              <span class="label">Accueil</span>
            </router-link>
          </li>
          <li v-if="rwAccess" v-show="!editing" class="green">
            <a href="#" @click.prevent="createNewElement($route.params.formname)">
            <span class="icon"><span class="icon-plus"></span></span>
              <span class="label">Nouveau</span>
            </a>
          </li>
          <li v-show="!editing" class="blue">
            <router-link v-show="!editing" :to="'/' + $route.params.formname">
              <span class="icon"><span class="icon-list"></span></span>
              <span class="label">Liste</span>
            </router-link>
          </li>
          <li v-if="rwAccess" v-show="!editing && versions.currentVersion + 1 == versions.totalVersions" class="violet">
            <a href="#" @click.prevent="editing = true">
            <span class="icon"><span class="icon-edit"></span></span>
              <span class="label">Éditer</span>
            </a>
          </li>
          <li v-if="rwAccess" v-show="versions.currentVersion + 1 < versions.totalVersions" class="red"> 
            <a href="#" @click.prevent="restoreCurrent">
              <span class="icon"><span class="icon-reuse"></span></span>
              <span class="label">Restaurer</span>
            </a>
          </li>
          <li v-show="formValid && editing" class="blue" >
            <a href="#" @click.prevent="saveElement">
              <span class="icon"><span class="icon-floppy-disk"></span></span>
              <span class="label">Enregistrer</span>
            </a>
          </li>
          <li v-show="editing" class="red">
            <a href="#" @click.prevent="editing = false; data['dateCreation'] ? loadInfos() : $router.back()">
              <span class="icon"><span class="icon-cancel"></span></span>
              <span class="label">Annuler</span>
            </a>
          </li>
          <li class="small" :class="{ 'red': $store.state.userInfos.connected, 'blue': !$store.state.userInfos.connected }">
            <a :href="'/login/' + encodedPath" v-if="!$store.state.userInfos.connected">
              <span class="icon"><span class="icon-lock"></span></span>
              <span class="label">Connexion</span>
            </a>
            <a href="/logout" v-if="$store.state.userInfos.connected">
              <span class="icon"><span class="icon-sign-out"></span></span>
              <span class="label">{{ 'Déconnexion : ' + $store.state.userInfos.fullName }}</span>
            </a>
          </li>
        </ul>
      </div>
      <!-- <div class="menu-bar">
        <a v-show="editing" href="#" class="button w-150 red" @click.prevent="editing = false; data['dateCreation'] ? loadInfos() : $router.back()"><i aria-hidden="true" class="fa fa-times"></i> Annuler</a>
      </div> -->
      <!-- <div class="menu-bar">
        <a v-if="rwAccess" v-show="!editing" href="#" class="button w-150 green" @click.prevent="createNewElement($route.params.formname)"><i aria-hidden="true" class="fa fa-plus"></i> Nouveau</a>
        <router-link v-show="!editing" class="button w-150 blue" :to="'/' + $route.params.formname"><i aria-hidden="true" class="fa fa-list"></i> Liste</router-link>
        <a v-if="rwAccess" v-show="!editing && versions.currentVersion + 1 == versions.totalVersions" href="#" class="button w-150 violet" @click.prevent="editing = true"><i aria-hidden="true" class="fa fa-pencil"></i> Éditer</a>
        <a v-if="rwAccess" v-show="versions.currentVersion + 1 < versions.totalVersions" href="#" class="button w-150 red" @click.prevent="restoreCurrent"><i aria-hidden="true" class="fa fa-recycle"></i> Restaurer</a>
        <a v-show="editing" :class="{'disabled': !formValid}" href="#" class="button w-150 blue" @click.prevent="saveElement"><i aria-hidden="true" class="fa fa-floppy-o"></i> Enregistrer</a>
        <a v-show="editing" href="#" class="button w-150 red" @click.prevent="editing = false; data['dateCreation'] ? loadInfos() : $router.back()"><i aria-hidden="true" class="fa fa-times"></i> Annuler</a>
      </div> -->
      <table class="table-form" v-if="form && data">
        <tr><td colspan="2">
          <h2>{{ form.titre }}</h2>
        </td></tr>
        <tr>
          <td colspan="2">
            <h3>
              {{ data[form.champIndex] }}
              <span v-show="versions.totalVersions > 1 && !editing">
                <br>
                <router-link :to="'/' + $route.params.formname + '/' + versions.firstIndex" :class="{'disabled' : !versions.firstIndex}"><i class="fa fa-angle-double-left fa-2x" aria-hidden="true"></i></router-link>
                <router-link :to="'/' + $route.params.formname + '/' + versions.prevIndex" :class="{'disabled' : !versions.prevIndex}"><i class="fa fa-angle-left fa-2x" aria-hidden="true"></i></router-link>
                Version {{ versions.currentVersion + 1 }}/{{ versions.totalVersions }} du {{ formatDate(data.dateCreation) }}              
                <router-link :to="'/' + $route.params.formname + '/' + versions.nextIndex" :class="{'disabled' : !versions.nextIndex}"><i class="fa fa-angle-right fa-2x" aria-hidden="true"></i></router-link>
                <router-link :to="'/' + $route.params.formname + '/' + versions.lastIndex" :class="{'disabled' : !versions.lastIndex}"><i class="fa fa-angle-double-right fa-2x" aria-hidden="true"></i></router-link>
              </span>
            </h3>
          </td>
        </tr>
        <tbody v-for="section in form.sections">
          <tr v-if="form.sections.length > 1">
            <td colspan="2"><h4>{{ section.titre }}</h4></td>
          </tr>
          <tr 
            is="champ"
            v-for="champ in section.champs"
            v-show="!champ.invisible"
            :champ="champ"
            :editable="editing"
            v-model="data[champ.nom]"
            :check="check"
            :valide.sync="valids[champ.nom]"
            :formname="$route.params.formname"
          ></tr>
        </tbody>
      </table>
    </div>
  `
})