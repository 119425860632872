const configForm = Vue.component('config-form', {
  data: () => ({
    query: "",
    fieldsInConf: [],
    fieldsInDb: [],
    comparativeArray: [],
    availableDbFields: [],
    dropQuery : '',
    addQuery : '',
    changeQuery : ''
  }),

  methods:  {
    updateQueries: function () {
      if (this.availableDbFields.length === 0) { this.dropQuery = '' }
      else {
        this.dropQuery = "ALTER TABLE `" + this.$route.params.formname + "` " + this.availableDbFields.map(field => "DROP COLUMN `" + field.name + "`").join(',') + ';'
      }

      let columnsToAdd = this.comparativeArray.filter(el => el[0] === null)
      if (columnsToAdd.length === 0) { this.addQuery = '' }
      else {
        this.addQuery = "ALTER TABLE `" + this.$route.params.formname + "` " + columnsToAdd.map(field => "ADD COLUMN `" + field[1].name + "` " + field[1].type).join(',') + ';'
      }
      
      let columnsToChange = this.comparativeArray.filter(el => el[0] !== null && el[1] !== null && el[0].name !== el[1].name)
      console.log('columnsToChange :', columnsToChange);
      if (columnsToChange.length === 0) { this.changeQuery = '' }
      else {
        this.changeQuery = "ALTER TABLE `" + this.$route.params.formname + "` " + columnsToChange.map(field => "CHANGE COLUMN `" + field[0].name + "` `" + field[1].name + "` " + field[0].type).join(',') + ';'
      }
      
    },
    removeDbField: function (el) {
      this.availableDbFields.push(el[0])
      el[0] = null
      this.updateQueries()
    },
    setDbField: function (el, field) {
      if (el[0] !== null) {
        this.availableDbFields.push(el[0])
      }
      el[0] = field
      this.availableDbFields.splice(this.availableDbFields.indexOf(field), 1)
      this.updateQueries()
    },
    initComparativeArray: function () {
      let iConf = this.fieldsInConf.length > 0 ? 0 : null,
          iDb = this.fieldsInDb.length > 0 ? 0 : null
      this.comparativeArray.splice(0, this.comparativeArray.length)

      while(iConf !== null && iDb !== null) {

        if (iDb === null) {
          this.comparativeArray.push([null, this.fieldsInConf[iConf]])
          iConf ++
        } else if (iConf === null) {
          this.comparativeArray.push([this.fieldsInDb[iDb], null])
          iDb ++
        } else if (this.fieldsInConf[iConf].name === this.fieldsInDb[iDb].name) {
          
          this.comparativeArray.push([this.fieldsInDb[iDb], this.fieldsInConf[iConf]])
          iConf ++
          iDb ++
        } else if (this.fieldsInConf[iConf].name < this.fieldsInDb[iDb].name) {
          this.comparativeArray.push([null, this.fieldsInConf[iConf]])
          iConf ++
        } else if (this.fieldsInConf[iConf].name > this.fieldsInDb[iDb].name) {
          this.availableDbFields.push(this.fieldsInDb[iDb])
          // this.comparativeArray.push([this.fieldsInDb[iDb], null])
          iDb ++
        }

        if (iConf >= this.fieldsInConf.length) { iConf = null }
        if (iDb >= this.fieldsInDb.length) { iDb = null }
      }
    },
    loadInfos: function () {
      let me = this
      this.server.get('/get/configinfos/' + this.$route.params.formname).then((data) => {
        me.query        = data.query
        me.fieldsInConf = data.fieldsInConf
        me.fieldsInDb   = data.fieldsInDb
        me.initComparativeArray()
        me.updateQueries()
      })
    }
  },
  mounted: function () {
    this.loadInfos()
  },
  template: `
    <div class="config-form">
      <table>
        <tr>
          <th>Requête de création de la table</th>
          <td colspan="2" class="query">{{ query }}</td>
        </tr>
        <tr>
          <th>Champs dans le fichier de configuration</th>
          <th>Champs dans la base</th>
          <th>Commentaire</th>
        </tr>
        <tr v-for="el in comparativeArray">
          <td>{{el[1] ? el[1].name : ''}}</td>
          <td>
            <a href="#" @click.prevent="removeDbField(el)" v-show="el[0]" class="remove-field">
              {{ el[0] ? el[0].name : '' }} <i class="fa fa-times" aria-hidden="true"></i>
            </a>
            <a href="#" v-for="field in availableDbFields" v-if="el[1].type == field.type" @click.prevent="setDbField(el, field)" class="add-field">
              {{ field.name }} <i class="fa fa-plus" aria-hidden="true"></i>
            </a>
          </td>
          <td>
            <!-- <span v-show="!el[1]">Les données du champs {{ el[0] ? el[0].name : '' }} vont disparaître</span> -->
            <span v-show="!el[0]">La colonne {{ el[1] ? el[1].name : '' }} va être créé</span>
            <span v-show="el[0] && el[1] && (el[0] ? el[0].name : '') != (el[1] ? el[1].name : '')">La colonne {{ el[0] ? el[0].name : '' }} sera renommée {{ el[1] ? el[1].name : '' }}</span>
          </td>
        </tr>
        <tr v-show="availableDbFields.length > 0">
          <th colspan="3">
            ATTENTION LES DONNÉES EXISTANTES DES CHAMPS SUIVANTS VONT ÊTRE SUPPRIMÉES :
            <ul>
              <li v-for="field in availableDbFields">
                {{ field.name }}
              </li>
            </ul>
          </th>
        </tr>
        <tr>
          <th>Requête(s) de modification de la table</th>
          <td colspan="2" class="query">
            {{ dropQuery }}<br>
            {{ changeQuery }}<br>
            {{ addQuery }}            
          </td>
        </tr>
      </table>
    </div>
  `
})