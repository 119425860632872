Vue.component('in-ac', {
  props: ['value', 'field', 'formname'],
  watch: {
    value: function (v) {
      this.value = v ? v : '';
    }
  },
  methods: {
    change: function (e) {
      this.$emit('input', this.value);
    },
    changeKey: function (e) { 
      if (e.keyCode === 13) {
        this.$emit('keyup', e);
      }
    }
  },
  mounted: function () {
    var me = this
    // console.log('this.$el :', this.$el.querySelector('input'));
    new autoComplete({
      data: {
        src: function () {
          return new Promise((resolve, reject) => {
            const query = me.$el.querySelector('input').value
            if (query.trim() === '') {
              resolve([])
            } else {
              me.server.get('/get/autocomplete/' + me.formname + '/' + me.field + '/' + query, false).then((data) => {
                resolve(data)
              })
            }
          })
        },
        cache: false
      },
      selector: () => this.$el.querySelector('input'),
      resultsList: {
        render: true,
        container: source => {
          // console.log('source :', source);
          source.removeAttribute('id')
          source.classList.add('autoComplete-list')
        }
      },
      onSelection: feedback => {
        console.log('feedback :', feedback);
        // me.$el.querySelector('input').value = feedback.selection.value
        me.value = feedback.selection.value
        me.$emit('input', feedback.selection.value)
        me.$emit('change', feedback.selection.value)
      },
      trigger: {
        event: ["input", "focusin", "focusout"]
      },
      highlight: true
    })

    this.$el.querySelector('input').addEventListener('focus', () => {
      me.$el.querySelector('.autoComplete-list').style.display = 'block'
    })
    this.$el.querySelector('input').addEventListener('blur', () => {
      me.$el.querySelector('.autoComplete-list').style.display = 'none'
    })
    
    // $(this.$el).find('input').autocomplete({ 
    //   serviceUrl: URL_SERVEUR + 'ac.php?form=' + this.$store.state.formulaire.nom +'&champ=' + this.champ,
    //   onSelect: function(suggestion) {
    //     me.value = suggestion.value
    //     me.$emit('input', me.value)
    //     me.$emit('change', me.value)
    //   },
    //   noCache: true
    // })
    
  },
  template: `
    <div class="in-ac">
      <input @change="change" @keyup="changeKey" v-model="value" type="text">
    </div>
  `
})
