const formsList = Vue.component('forms-list', {
  data: () => {
    return {
      forms: []
    }
  },
  mounted: function () {
    console.log('this :', this);
    console.log('$$server :', this.server);
    this.server.get('/get/forms').then((data) => {this.forms = data})
  },
  template: `
    <div class="forms-list">
      <h1>LISTE DES REGISTRES</h1>
      <ul>
        <router-link v-for="form in forms" :to="'/' + form.name" tag="li">
          {{ form.title }}
        </router-link>
      </ul>
    </div>
  `
})