Vue.component('in-multi', {
  props: ['value', 'ac', 'champ', 'formname'],
  data: function() {
    return {
      //values: [],
      nouvelItem: ''
    }
  },
  watch: {
    value: function (v) {
      this.value = v ? v : '';
    }
  },
  methods: {
    supprime: function (v) {
      var tmp = this.value.split('|'), i, retour = [];
      for (i = 0; i < tmp.length; i += 1) {
        if (tmp[i] !== v) {
          retour.push(tmp[i]);   
        }
      }
      this.value = retour.join('|');
      this.$emit('input', this.value);
    },
    ajouteKey: function (e) {
      if (e.keyCode === 13) {
        this.ajoute()
        
      }
    },
    ajoute: function () {
      if (this.value.trim() === '') {
        this.value =  this.nouvelItem;
      } else {
        this.value = this.value + '|' + this.nouvelItem;
      }
      this.nouvelItem = '';
      this.$emit('input', this.value)
    }
  },
  mounted: function () {
    if (!this.value) {
      this.value = '';
      this.$emit('input', this.value);
    }
  },
  template: `
    <div class="in-multi">
      <ul v-if="value">
        <li v-for="val in value.split('|')">
          <i class="fa fa-trash fa-lg" aria-hidden="true" @click="supprime(val)"></i>
          {{ val }}
        </li>
      </ul>
      <input v-if="!ac" type="text" @keyup="ajouteKey" v-model="nouvelItem">
      <in-ac v-model="nouvelItem" v-if="ac" :field="champ" :formname="formname" @change="ajoute" @keyup="ajouteKey"></in-ac>
    </div>
  `
})