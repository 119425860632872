Vue.component('multi-button', {
  props: ['values'],
  data: function () {
    return {
      choiceopen: false
    }
  },
  methods: {
    emitEvent: function (format) {
      this.$emit('choose', format)
    }
  },
  template: `
    <a href="#" class="multi-button button blue w-200" @click.prevent="choiceopen = !choiceopen">
      <slot></slot>
      <div v-if="choiceopen" class="button">
        <a href="#" v-for="value in values" @click.prevent="emitEvent(value.data)">{{ value.label }}</a>
      </div>
      <!-- {{ values }} -->
    </a>
  `
})